<template>
  <div id="app" class="container-fluid padding0">
    <Header />
    <router-view></router-view>
    <Footer />
  </div>
</template>
<script>
import Header from '@/views/components/Header'
import Footer from '@/views/components/Footer'
export default {
  components: {
    Header,
    Footer
  },
  watch: {
    '$route' () {
      this.setTitle()
    }
  },
  created() {
    this.setTitle()
  },
  methods: {
    setTitle() {
      let title = 'Timebit SG'
      console.log(this.$route.meta)
      if (this.$route && this.$route.meta && this.$route.meta.title) {
        document.title = this.$route.meta.title + ' | ' + title
      }
    }
  }
}
</script>
