<template>
  <footer>


    <!-- Start: Contact Us section -->
    <section id="contact_us" class="space_section">
      <div class="content_width">
        <hgroup class="group_title">
          <h2>Get In Touch</h2>
        </hgroup>
        <div class="row">
          <div class="col-12">
            <ul>
              <li><i class="lni-envelope"></i> info@timebit.sg</li>
              <li><i class="lni-telegram"></i> Join Us on Telegram</li>
              <div class="clearfix"></div>
            </ul>
            <form method="POST" @submit.prevent="submit">
              <div class="row">
                <div class="col-12 col-sm-6">
                  <div class="form-group">
                    <input type="text" class="form-control" placeholder="Your name"/>
                  </div>
                </div>
                <div class="col-12 col-sm-6">
                  <div class="form-group">
                    <input type="email" class="form-control" placeholder="Your email"/>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <input type="text" class="form-control" placeholder="Your title"/>
              </div>
              <div class="form-group">
                <textarea class="form-control" rows="3" placeholder="Your message"></textarea>
              </div>

              <button type="submit" class="btn btn_submit">Send Message</button>
            </form>
          </div>
        </div>
      </div>
    </section>
    <!-- End: Contact Us section -->


    <!-- Start: Footer Bottom section -->
    <section id="footer_bottom">
      <div class="content_width">
        <div class="group_top">
          <router-link :to="{name: 'Home'}">
            <img class="img-fluid" src="/assets/images/logo-timebit.png" title="" alt=""/>
          </router-link>
          <ul>
            <li><router-link :to="{name: 'Faqs'}">FAQ</router-link></li>
            <li><a href="#">Privacy policy</a></li>
            <li><a href="#">Term of Use</a></li>
          </ul>
          <div class="clearfix"></div>
        </div>
        <hr/>
        <div class="group_bottom">
          <p>© 2020 timebit.sg. All right reserved</p>
          <ul class="fanpages">
            <li><a target="_blank" href="https://www.facebook.com/TimeBitGlobal"><i
                class="lni-facebook-original"></i></a></li>
            <li><a target="_blank" href="https://www.instagram.com/timebit_global"><i class="lni-instagram"></i></a>
            </li>
            <li><a target="_blank" href="https://twitter.com/TimeBit_Global"><i class="lni-twitter-original"></i></a>
            </li>
            <li><a target="_blank" href="https://t.me/Timebit_global"><i class="lni-telegram"></i></a></li>
            <li><a target="_blank" href="https://m.youtube.com/channel/UCAyMDAb5AgLP51ffDNKdC9g"><i
                class="lni-youtube"></i></a></li>
            <li><a target="_blank" href="http://i.youku.com/timebit"><img src="/assets/images/youku.png"></a></li>
            <div class="clearfix"></div>
          </ul>
          <div class="clearfix"></div>
        </div>
      </div>

    </section>
    <!-- End: Footer Bottom section -->


  </footer>
</template>

<script>
export default {
  methods: {
    submit() {

    }
  }
}
</script>
