import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/views/pages/Home'
import PageNotFound from '@/views/pages/PageNotFound'
import Saas from '@/views/pages/Saas'
import AboutUs from '@/views/pages/AboutUs'
import Faqs from '@/views/pages/Faqs'
import Services from '@/views/pages/Services'
import Exchange from '@/views/pages/Exchange'
import Wallet from '@/views/pages/Wallet'
import Blog from '@/views/pages/Blog'
import BlogList from '@/views/pages/blog/List'
import CloudWallet from '@/views/pages/blog/CloudWallet'
import BlockchainIndustry from '@/views/pages/blog/BlockchainIndustry'
import CryptocurrencyWallet from '@/views/pages/blog/CryptocurrencyWallet'
import FreeWithTimebit from '@/views/pages/blog/FreeWithTimebit'
import ListedCoinmarketcap from '@/views/pages/blog/ListedCoinmarketcap'
import PartnerConference from '@/views/pages/blog/PartnerConference'
import Portfolio from '@/views/pages/Portfolio'
import TimebitExchange from '@/views/pages/portfolio/TimebitExchange'
import TimebitOtc from '@/views/pages/portfolio/TimebitOtc'
import TimebitWallet from '@/views/pages/portfolio/TimebitWallet'

const router = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {title: 'Home'}
  },
  {
    path: '/saas',
    name: 'Saas',
    component: Saas,
    meta: {title: 'Saas'}
  },
  {
    path: '/about-us',
    name: 'About Us',
    component: AboutUs,
    meta: {title: 'About Us'}
  },
  {
    path: '/faqs',
    name: 'Faqs',
    component: Faqs,
    meta: {title: 'Faqs'}
  },
  {
    path: '/services',
    name: 'Services',
    component: Services,
    meta: {title: 'Services'}
  },
  {
    path: '/exchange',
    name: 'Exchange',
    component: Exchange,
    meta: {title: 'Exchange'}
  },
  {
    path: '/wallet',
    name: 'Wallet',
    component: Wallet,
    meta: {title: 'Wallet'}
  },
  {
    path: '/blog',
    name: 'Blog',
    component: Blog,
    meta: {title: 'Blog'},
    redirect: {name: 'BlogList'},
    children: [
      {
        path: '',
        name: 'BlogList',
        component: BlogList,
        meta: {title: 'Blog'}
      },
      {
        path: 'cloud-wallet',
        name: 'CloudWallet',
        component: CloudWallet,
        meta: {title: 'Cloud Wallet'}
      },
      {
        path: 'enabling-thousands-of-smes-to-enter-the-blockchain-industry',
        name: 'BlockchainIndustry',
        component: BlockchainIndustry,
        meta: {title: 'Enabling Thousands of SMEs to Enter the Blockchain Industry'}
      },
      {
        path: 'what-is-a-cryptocurrency-wallet-how-to-create-a-cryptocurrency-wallet',
        name: 'CryptocurrencyWallet',
        component: CryptocurrencyWallet,
        meta: {title: 'What is a cryptocurrency wallet, how to create a cryptocurrency wallet'}
      },
      {
        path: 'starting-your-own-cryptocurrency-business-becomes-hassle-free-with-timebit',
        name: 'FreeWithTimebit',
        component: FreeWithTimebit,
        meta: {title: 'Starting Your Own Cryptocurrency Business Becomes Hassle-free with TimeBit'}
      },
      {
        path: 'timebit-is-now-listed-on-coinmarketcap',
        name: 'ListedCoinmarketcap',
        component: ListedCoinmarketcap,
        meta: {title: 'TimeBit is now listed on CoinMarketCap'}
      },
      {
        path: 'timebit-international-partner-conference-2019',
        name: 'PartnerConference',
        component: PartnerConference,
        meta: {title: 'Timebit International Partner conference 2019'}
      },
    ]
  },
  {
    path: '/portfolio',
    name: 'Portfolio',
    component: Blog,
    meta: {title: 'Portfolio'},
    redirect: {name: 'Portfolio'},
    children: [
      {
        path: '',
        name: 'Portfolio',
        component: Portfolio,
        meta: {title: 'Portfolio'}
      },
      {
        path: 'timebit-exchange',
        name: 'TimebitExchange',
        component: TimebitExchange,
        meta: {title: 'Timebit Exchange'}
      },
      {
        path: 'timebit-otc',
        name: 'TimebitOtc',
        component: TimebitOtc,
        meta: {title: 'Timebit OTC'}
      },
      {
        path: 'timebit-wallet',
        name: 'TimebitWallet',
        component: TimebitWallet,
        meta: {title: 'Timebit Wallet'}
      }
    ]
  },
  {
    path: '/404',
    name: 'PageNotFound',
    component: PageNotFound,
    meta: {title: '404 Page'}
  },
  {
    path: '*', redirect: '/404'
  }
]

// Router catch exception
const originalPush = Router.prototype.push
Router.prototype.push = function push (location) {
  return originalPush.call(this, location)
    .catch(() => console.log('Routing is not changed'))
}
Vue.use(Router)
export default new Router({
  mode: 'history',
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: router
})
